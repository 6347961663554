<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="header-marcar_consulta bg-gradient-primary">
          <b-row align-v="center">
            <b-col>
              <h1>
                <feather-icon icon="UsersIcon" size="30" class="mr-50" />
                Profissionais Cadastrados
              </h1>
              <h4 class="mt-1">Selecione um profissional:</h4>
            </b-col>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="button-right ml-1 botao-white-outline"
              to="/novoProfissional"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">Adicionar Profissional </span>
            </b-button>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-form @submit.prevent="fetchProfissionais">
      <b-row>
        <b-col md="4">
          <b-input-group class="input-group-merge mb-2">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="filtro.firstName"
              placeholder="Primeiro Nome do Profissional"
            />
          </b-input-group>
        </b-col>
        <b-col md="4">
          <b-input-group class="input-group-merge mb-2">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="filtro.lastName"
              placeholder="Útimo Nome do Profissional"
            />
          </b-input-group>
        </b-col>

        <b-col md="4">
          <b-input-group class="input-group-merge mb-2">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="filtro.cpf"
              type="number"
              placeholder="CPF do Profissional"
            />
          </b-input-group>
        </b-col>

        <b-col md="4">
          <b-input-group class="input-group-merge mb-2">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="filtro.city"
              placeholder="Cidade do Profissional"
            />
          </b-input-group>
        </b-col>

        <b-col md="4">
          <b-input-group class="input-group-merge mb-2">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="filtro.state"
              placeholder="Estado do Profissional"
            />
          </b-input-group>
        </b-col>

        <!-- <b-col md="4">
          <flat-pickr
            v-model="filtro.createdAtTo"
            :config="configFlatPickr"
            class="form-control"
            name="date"
            placeholder="Criado em"
          />
        </b-col> -->

        <b-col md="4">
          <b-button type="submit" variant="primary" block @click="fetchProfissionais()">
            Filtrar
          </b-button>
        </b-col>
      </b-row>
    </b-form>

    <b-card no-body class="mb-0">
      <b-table
        ref="refUserListTable"
        style="min-height: 50vw"
        class="position-relative"
        :items="fetchedProfissionais"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(nome)="data">
          <h5>{{ data.item.firstName }} {{ data.item.lastName }}</h5>
        </template>

        <template #cell(cadastro)="data">
          {{ data.item.createdAt }}
        </template>

        <template #cell(localizacao)="data">
          {{ data.item.person.city }} -
          {{ data.item.person.state }}
        </template>

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.role
            }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(
              data.item.person.status
            )}`"
            class="text-capitalize"
          >
            {{ resolveUserStatusText(data.item.person.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- <b-dropdown-item
              :to="{ name: 'pacienteEdit', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item> -->
            <!--Mostrar apenas se tiver alguma consulta marcada -->

            <b-dropdown-item
              :to="{ name: 'profissionalEdit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar Profissional</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
  BLink,
  BMedia,
  BAvatar,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BForm,
} from 'bootstrap-vue'
import { title } from '@/@core/utils/filter'
import professionalService from '@/services/professionalService'
import { hideSpinner, showSpinner } from '@/services/spinService'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    BButton,
    BRow,
    BCol,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BForm,
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.fetchProfissionais()
  },

  methods: {
    async fetchProfissionais() {
      showSpinner()
      this.fetchedProfissionais = await professionalService.getAll(this.filtro)
      hideSpinner()
    },
    // resolveUserRoleVariant: role => {
    //   if (role === 'paciente') return 'primary'
    //   if (role === 'author') return 'warning'
    //   if (role === 'maintainer') return 'success'
    //   if (role === 'editor') return 'info'
    //   if (role === 'admin') return 'danger'
    //   return 'primary'
    // },
    // resolveUserRoleIcon: role => {
    //   if (role === 'paciente') return 'UserIcon'
    //   if (role === 'author') return 'SettingsIcon'
    //   if (role === 'maintainer') return 'DatabaseIcon'
    //   if (role === 'editor') return 'Edit2Icon'
    //   if (role === 'admin') return 'ServerIcon'
    //   return 'UserIcon'
    // },
    resolveUserStatusText: status => {
      if (status === 'abandono') return 'warning'
      if (status === 'ACTIVE') return 'ativo'
      if (status === 'INACTIVE') return 'inativo'
      if (status === 'alta') return 'primary'
      if (status === 'óbito') return 'danger'
      return ''
    },

    resolveUserStatusVariant: status => {
      if (status === 'abandono') return 'warning'
      if (status === 'ACTIVE') return 'success'
      if (status === 'INACTIVE') return 'warning'
      if (status === 'alta') return 'primary'
      if (status === 'óbito') return 'danger'
      return 'primary'
    },
  },
  data: () => ({
    fetchedProfissionais: [],
    filtro: {},
    users: [],
    perPage: 10,
    totalUsers: 0,
    currentPage: 1,
    perPageOptions: [10, 25, 50, 100],
    searchQuery: '',
    sortBy: 'id',
    isSortDirDesc: true,
    roleFilter: null,
    planFilter: null,
    statusFilter: null,
    tableColumns: [
      { key: 'id', sortable: true },
      { key: 'cpf', label: 'CPF', sortable: true },
      { key: 'nome', label: 'Nome', sortable: true },
      { key: 'cadastro', label: 'Cadastro', sortable: true },
      { key: 'localizacao', label: 'Localização', sortable: true },
      { key: 'status', sortable: true },
      { key: 'actions', label: 'Acesso', sortable: false },
    ],
  }),
}
</script>
